import {
	AppointmentRequest as AppointmentRequestProto,
	GetAppointmentRequestResponse,
	ListAppointmentRequestsResponse
} from '@agentero/grpc-clients/grpc/appointment-fe';
import { Status } from '@agentero/grpc-clients/grpc/shared/appointment';
import { getDateFromGrpc } from '@agentero/utils';

import {
	CarrierAppointmentStatus,
	parseCarrierAppointmentStatus
} from '../carrierappointment/CarrierAppointmentStatus';
import { RaterSchema, parseRaterForm } from '../quote/rater/RaterSchema';
import { Carrier, getCarrierFromProto } from '../shared/Carrier';

export type AppointmentRequest = {
	agencyId: string;
	agencyName: string;
	carrier: Carrier;
	created: Date;
	state: string;
	status: AppointmentRequestStatus;
	appointmentStatus: CarrierAppointmentStatus;
	questionMap?: Record<string, string>;
	schemaAppointmentForm?: RaterSchema;
	admName?: string;
	updatedByName?: string;
	rejectionReason?: string;
};

export enum AppointmentRequestStatus {
	Approve = 'Approved',
	Rejected = 'Rejected',
	Pending = 'Pending'
}

const map = {
	[Status.STATUS_APPROVED]: AppointmentRequestStatus.Approve,
	[Status.STATUS_REJECTED]: AppointmentRequestStatus.Rejected,
	[Status.STATUS_PENDING]: AppointmentRequestStatus.Pending,
	[Status.STATUS_UNSPECIFIED]: AppointmentRequestStatus.Pending
};

const parseAppointmentRequestStatus = (status: Status): AppointmentRequestStatus => {
	return map[status];
};

const mapToProto = {
	[AppointmentRequestStatus.Approve]: Status.STATUS_APPROVED,
	[AppointmentRequestStatus.Rejected]: Status.STATUS_REJECTED,
	[AppointmentRequestStatus.Pending]: Status.STATUS_PENDING
};

export const parseAppointmentRequestStatusToProto = (status: AppointmentRequestStatus): Status => {
	return status ? mapToProto[status] : Status.STATUS_UNSPECIFIED;
};

export const parseAppointmentRequest = (
	response: AppointmentRequestProto.AsObject
): AppointmentRequest => {
	return {
		agencyId: response.agencyId,
		agencyName: response.agencyName,
		carrier: getCarrierFromProto(response.carrier),
		state: response.state,
		status: parseAppointmentRequestStatus(response.status),
		created: getDateFromGrpc(response.createdAt),
		appointmentStatus: parseCarrierAppointmentStatus(response.appointmentStatus),
		admName: response.admOwnerName,
		updatedByName: response.updatedByName,
		rejectionReason: response.rejectionReason
	};
};

export const parseAppointmentRequestWithQuestions = (
	response: GetAppointmentRequestResponse.AsObject
): AppointmentRequest => {
	return {
		agencyId: response.agencyId,
		agencyName: response.agencyName,
		carrier: getCarrierFromProto(response.carrier),
		state: response.state,
		status: parseAppointmentRequestStatus(response.status),
		created: new Date(),
		questionMap: response.questionsMap ? Object.fromEntries(response.questionsMap) : undefined,
		schemaAppointmentForm: response.questionsSchema && parseRaterForm(response.questionsSchema),
		appointmentStatus: parseCarrierAppointmentStatus(response.appointmentStatus),
		rejectionReason: response.rejectionReason
	};
};

export type ListAppointmentRequests = {
	totalCount: number;
	totalPages: number;
	list: AppointmentRequest[];
};

export const parseListAppointmentRequests = (
	response: ListAppointmentRequestsResponse.AsObject,
	pageSize: number
): ListAppointmentRequests => {
	return {
		totalCount: response.totalCount,
		totalPages: Math.ceil(response.totalCount / pageSize),
		list: response.appointmentRequestsList.map(parseAppointmentRequest)
	};
};
